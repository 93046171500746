import { render, staticRenderFns } from "./FlTopMovies.vue?vue&type=template&id=35372488&scoped=true&"
import script from "./FlTopMovies.vue?vue&type=script&lang=ts&"
export * from "./FlTopMovies.vue?vue&type=script&lang=ts&"
import style0 from "./FlTopMovies.vue?vue&type=style&index=0&id=35372488&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35372488",
  null
  
)

export default component.exports